import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Біз туралы | RoadMasters автомектеп туралы
			</title>
			<meta name={"description"} content={"RoadMasters жүргізуші мектебі қарапайым, бірақ күшті көзқарастан басталды: көлік жүргізуді үйрету және қабылдау тәсілін өзгерту."} />
			<meta property={"og:title"} content={"Біз туралы | RoadMasters автомектеп туралы"} />
			<meta property={"og:description"} content={"RoadMasters жүргізуші мектебі қарапайым, бірақ күшті көзқарастан басталды: көлік жүргізуді үйрету және қабылдау тәсілін өзгерту."} />
			<meta property={"og:image"} content={"https://dijespalma.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://dijespalma.com/img/7276280.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://dijespalma.com/img/7276280.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://dijespalma.com/img/7276280.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://dijespalma.com/img/7276280.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://dijespalma.com/img/7276280.png"} />
			<meta name={"msapplication-TileImage"} content={"https://dijespalma.com/img/7276280.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Біз туралы
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					RoadMasters жүргізуші мектебі қарапайым, бірақ күшті көзқарастан басталды: көлік жүргізуді үйрету және қабылдау тәсілін өзгерту. Біздің мақсатымыз әрқашан көлік жүргізуді үйретіп қана қоймай, әрбір оқушының бойында жауапкершілік, сенімділік және жолға деген құрмет сезімін ояту болды.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://dijespalma.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Біздің Философия
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Жүргізу - бұл көлікті маневрлеуден тыс шеберлік деп санаймыз. Бұл жолды түсіну, басқа жүргізушілерді құрметтеу және күтпеген жағдайға дайын болу. Бұл философия біздің оқыту әдістемесінің негізінде жатыр, бұл бізді жай ғана көлік мектебінен артық етеді.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://dijespalma.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							RoadMasters нені ерекшелейді?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Біртұтас оқытуға назар аударыңыз: Біздің курстар техникалық дағдылардан бастап ақыл-ой және эмоционалды дайындыққа дейін, жан-жақты оқу тәжірибесін қамтамасыз ететін көлік жүргізудің барлық қырларын қамтуға арналған.
Арнайы нұсқау: Әрбір оқушы бірегей екенін мойындай отырып, біз оқыту әдістемелерімізді оқытудың жеке стилі мен қарқынына сай етіп реттейміз.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://dijespalma.com/img/8.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Көлік жүргізу сабақтарынан басқа
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Қоғамдастықтың қатысуы: RoadMasters жол қауіпсіздігі науқандарына және білім беру бағдарламаларына қатыса отырып, қоғамдастықтың қатысуын міндеттейді.
Үздіксіз жетілдіру: Біз жүргізуді үйрету және көлік технологиясы саласындағы соңғы жаңалықтардан хабардар боламыз, ең соңғы ақпарат пен техниканы қамту үшін курстарымызды үнемі жаңартып отырамыз.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://dijespalma.com/img/1.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://dijespalma.com/img/9.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Автокөлік жүргізу тек А нүктесінен В нүктесіне жету ғана емес, сонымен қатар олардың арасындағы саяхат туралы болып табылатын RoadMasters жүргізуші мектебінің артындағы этика мен міндеттемені зерттеңіз.
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});